@media (min-height: 768px){

  #footer{
    margin-top: 27vh;
  }
}

.container {
  @media (min-width: 1366px) {
    max-width: 1280px;
  }

  @media (min-width: 1540px) {
    max-width: 1500px;
  }
}

.u-header--sticky-top {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
}

h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
}

.u-btn-primary,
.u-btn-primary.u-btn-hover-v2-1:hover {
  background-color: #8eb3c1;
}
